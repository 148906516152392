<template>
  <i>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0002 7.00009L16.5902 5.59009L10.2502 11.9301L11.6602 13.3401L18.0002 7.00009ZM22.2402 5.59009L11.6602 16.1701L7.48016 12.0001L6.07016 13.4101L11.6602 19.0001L23.6602 7.00009L22.2402 5.59009ZM0.410156 13.4101L6.00016 19.0001L7.41016 17.5901L1.83016 12.0001L0.410156 13.4101Z"
        fill="#45DB54"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: "IconAddDone"
}
</script>

<style scoped>

</style>
