<template>
  <i>
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
         preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
      <path fill="currentColor" d="m13 24l-9-9l1.414-1.414L13 21.171L26.586 7.586L28 9L13 24z"/>
    </svg>

  </i>
</template>

<script>
export default {
  name: "IconCheckMark"
}
</script>

<style scoped>

</style>
