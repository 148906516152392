<template>
  <i>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.78914 12.0001L7.36914 17.5901L5.95914 19.0001L0.369141 13.4101L1.78914 12.0001ZM2.23914 4.22006L12.8991 14.8901L11.6191 16.1701L7.43914 12.0001L6.02914 13.4101L11.6191 19.0001L14.3091 16.3101L19.1991 21.2001L20.6091 19.7901L3.64914 2.81006L2.23914 4.22006ZM17.1391 13.4901L23.6191 7.00006L22.1991 5.59006L15.7191 12.0701L17.1391 13.4901ZM17.9591 7.00006L16.5491 5.59006L12.8991 9.25006L14.3091 10.6601L17.9591 7.00006Z"
        fill="#DB4545"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: "IconRemoveDone"
}
</script>

<style scoped>

</style>
